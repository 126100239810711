import { Box } from "@mui/material";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import InputForm from "../forms/inputForm";
import SelectForm from "../forms/selectFormm";
import { useAppDispatch } from "../../../store";
import { useEffect, useState } from "react";
import useDebouncedEffect from "../../../hooks/useDebouncedEffect";
import DatePickerForm from "../forms/datePickerForm";
import dayjs from "dayjs";

const schema = yup.object({
  title: yup.string().required(),
});

const OcspConfigurationSearch = ({ params, setParams }: any) => {
  const [searchFilterDebounced, setSearchFilterDebounced] = useState("");
  const [configId, setConfigId] = useState("");
  const [ocspCertificateSubjectName, setOcspCertificateSubjectName] = useState("");
  const [issuerSubjectName, setIssuerSubjectName] = useState("");
  const [createdStart, setCreatedStart] = useState("");
  const [createdEnd, setCreatedEnd] = useState("");
  const [userActive, setUserActive] = useState(""); // TODO: NEED PARAMETER FROM API
  const dispatch = useAppDispatch();

  const {
    control,
    formState: { errors },
  } = useForm<{
    configId: string;
    ocspCertificateSubjectName: string;
    issuerSubjectName: string;
    createdStart: string;
    createdEnd: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(schema),
    defaultValues: {
      configId: "",
      ocspCertificateSubjectName: "",
      issuerSubjectName: "",
      createdStart: "",
      createdEnd: "",
    },
  });

  const optSelect = [
    {
      label: "Active",
      value: "ACTIVE",
    },
    {
      label: "Inactive",
      value: "INACTIVE",
    },
  ];

  const handleConfigId = (e: string) => { setConfigId(e); };
  const handleOcspCertificateSubjectNameSelect = (e: any) => { setOcspCertificateSubjectName(e); };
  const handleIssuerSubjectName = (e: any) => { setIssuerSubjectName(e); };
  const handleStatusSelect = (e: any) => { 
    if(e === null ){
      setUserActive('')  
    } else {
      setUserActive(e.value);
    }
  }; 
  const handleCreatedStart = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setCreatedStart(NewDate);
  };
  const handleCreatedEnd = (e: any) => {
    let NewDate: any;
    if (e !== null) {
      NewDate = dayjs(e).format("YYYY-MM-DD");
    } else {
      NewDate = "";
    }
    setCreatedEnd(NewDate);
  };

  useDebouncedEffect(() => setSearchFilterDebounced(configId), [configId], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(ocspCertificateSubjectName), [ocspCertificateSubjectName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(issuerSubjectName), [issuerSubjectName], 500);
  useDebouncedEffect(() => setSearchFilterDebounced(userActive), [userActive], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(createdStart), [createdStart], 500); 
  useDebouncedEffect(() => setSearchFilterDebounced(createdEnd), [createdEnd], 500); 

  useEffect(() => {
    setParams({
      ...params,
      uc_ocsp_cert_id: configId,
      ocsp_subject_name: ocspCertificateSubjectName,
      issuer_subject_name: issuerSubjectName,
      ocsp_config_created_start: createdStart,
      ocsp_config_created_end: createdEnd,
      status: userActive, 
    });
  }, [searchFilterDebounced]);
  

  return (
    <Box>
      <Box sx={{ display: "flex", flexDirection: "row", gap: "1rem", flex: 1 }}>
        <InputForm
          name="configId"
          errors={errors}
          control={control}
          required
          placeholder="Config ID"
          maxLength={100}
          isLoading={false}
          onChange={handleConfigId}
        />
        <InputForm
          name="ocspCertificateSubjectName"
          errors={errors}
          control={control}
          required
          placeholder="OCSP Certificate Subject Name"
          maxLength={100}
          isLoading={false}
          onChange={handleOcspCertificateSubjectNameSelect}
        />
        <InputForm
          name="issuerSubjectName"
          errors={errors}
          control={control}
          required
          placeholder="Issuer Subject Name"
          maxLength={100}
          isLoading={false}
          onChange={handleIssuerSubjectName}
        />
        <DatePickerForm
          name="createdStart"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created Start"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreatedStart}
        />
        <DatePickerForm
          name="createdEnd"
          defaultValue={null}
          control={control}
          errors={errors}
          placeholder="Created End"
          disabled={false}
          isLoading={false}
          required
          onChange={handleCreatedEnd}
        />
        <SelectForm 
          name="status"
          defaultValue={1}
          options={optSelect}
          placeholder="Status"
          disabled={false}
          isLoading={false}
          control={control}
          errors={errors}
          required
          isClear
          sxFL={{ backgroundColor: "red" }}
          onChangeForm={handleStatusSelect}
        />
      </Box>
    </Box>
  );
};

export default OcspConfigurationSearch;
