import { yupResolver } from "@hookform/resolvers/yup";
import { Box, Button, Grid, Typography } from "@mui/material";
import { Stack } from "@mui/system";
import { useForm } from "react-hook-form";
import {
  InputForm,
  SelectFormm,
} from "../../components/molecules/forms/index";
import { useLocation } from "react-router-dom";
import { useNavigate } from "react-router-dom";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import {
  getAllCertificates,
  getOcspById,
  createOcspConfiguration,
  updateOcspConfiguration,
  removeEventFlag,
  getCertificateDropdown
} from "../../store/feature/ocspConfigure";
import DetailDataSkeleton from "../../components/template/skeleton/DetailDataSkeleton";
import { LoadingButton } from "@mui/lab";
import ocspConfiguration from "../../utils/validation/ocspConfiguration";

const OcspConfigurationField = () => {

  // REGION: INIT VARIABLES
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const location = useLocation();
  const idParam = useParams();
  const { pathname } = location;
  const editFgroup = pathname.includes("edit");
  const {
    editDataOcspConfig,
    optSubjectName,
    optIssuerSubjectName,
    isLoadingOcspConfig,
    isLoadingEvent,
    eventFlag
  } = useAppSelector((state: RootState)=> state.ocspConfig)
  const { ocspConfig } = useAppSelector((state: RootState)=> state.user)
  const {
    uc_ca_cert_id,
    uc_crt_issuer_subject_name,
    uc_crt_subject_name,
    uc_ocsp_cert_id,
    uc_ocsp_config_created_at,
    uc_ocsp_config_id,
    uc_ocsp_config_status,
    uc_ocsp_config_uid,
    uc_ocsp_config_updated_at
   } = editDataOcspConfig
   const optStatusSelect = [
     {
       label: "Active",
       value: "Active",
     },
     {
       label: "Inactive",
       value: "Inactive",
     },
   ];

   
  // REGION: INIT FORM DATA
  const {
    control,
    reset,
    handleSubmit,
    formState: { errors },
  } = useForm<{
    uid: string;
    caCertId: string;
    certSubjectName: string;
    certIssuerSubjectName: string;
    ocspCertId: string;
    ocspConfigCreatedAt: string;
    ocspConfigId: string;
    ocspConfigStatus: string;
    ocspConfigUpdateAt: string;
  }>({
    reValidateMode: "onChange",
    resolver: yupResolver(ocspConfiguration),
    defaultValues: {
      uid: "",
      caCertId: "",
      certSubjectName: "",
      certIssuerSubjectName: "",
      ocspCertId: "",
      ocspConfigCreatedAt: "",
      ocspConfigId: "",
      ocspConfigStatus: "",
      ocspConfigUpdateAt: "",
    },
  });


  // REGION: SET FORM DATA
  useEffect(() => {
    if (editFgroup) {
      reset({
        uid: uc_ocsp_config_uid,
        caCertId: uc_ca_cert_id,
        certSubjectName: uc_ocsp_cert_id,
        certIssuerSubjectName: uc_ca_cert_id,
        ocspCertId: uc_ocsp_cert_id,
        ocspConfigCreatedAt: uc_ocsp_config_created_at,
        ocspConfigId: uc_ocsp_config_id,
        ocspConfigStatus: uc_ocsp_config_status,
        ocspConfigUpdateAt: uc_ocsp_config_updated_at
      });
    } else {
      reset();
    }
  }, [ editDataOcspConfig ]);


  // REGION: DISPATCH GET DATA
  useEffect(() => {
    if (idParam.id !== undefined) {
      console.log('hahaha get ==> ')
      dispatch(getOcspById(idParam.id));
    }
    dispatch(getCertificateDropdown({}));
  }, [ocspConfig]);


  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if(eventFlag) {
      navigate("/ocsp-configuration")
      dispatch(removeEventFlag())
    }
  }, [eventFlag]);


  // REGION: DISPATCH INSERT & UPDATE DATA
  const onSubmit = (e: any) => {
    if (editFgroup) {
      const updateData = {
        uid: e.uid,
        uc_ocsp_config_id: e.ocspConfigId,
        uc_ca_cert_id: e.certIssuerSubjectName,
        uc_ocsp_cert_id: e.certSubjectName,
        uc_ocsp_config_status: e.ocspConfigStatus,
        uc_ocsp_config_created_at: e.ocspConfigCreatedAt,
      }
      dispatch(updateOcspConfiguration(updateData));
    } else {
      const createData = {
        uc_ca_cert_id: e.certIssuerSubjectName,
        uc_ocsp_cert_id: e.certSubjectName,
        uc_ocsp_config_status: e.ocspConfigStatus,
      }
      dispatch(createOcspConfiguration(createData));
    }
  };

  // REGION: RETURN TSX
  return (
    <DetailDataSkeleton
    isLoading={isLoadingOcspConfig}
    isLoadingEvent={isLoadingEvent}
    inputCount={6}
    titleWidth={'350px'}
    >
    <Box component="form" onSubmit={handleSubmit(onSubmit)} paddingY="20px">
      <Typography variant="h3" fontFamily="Open Sans" mb={3}>
        {editFgroup ? "Update OCSP Configuration" : "Create OCSP Configuration"}
      </Typography>
      <SelectFormm
        name="certSubjectName"
        label="OCSP Certificate Subject Name"
        defaultValue={0}
        options={optSubjectName}
        placeholder="none"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        isSearch
        required
      />
      <SelectFormm
        name="certIssuerSubjectName"
        label="issuer Subject Name"
        defaultValue={0}
        options={optIssuerSubjectName}
        placeholder="none"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        isSearch
        required
      />
      <SelectFormm
        name="ocspConfigStatus"
        label="Status"
        defaultValue={1}
        options={optStatusSelect}
        placeholder="none"
        disabled={false}
        isLoading={false}
        control={control}
        errors={errors}
        required
      />
      {editFgroup && (
        <>
          <InputForm
            name="ocspConfigCreatedAt"
            label="Created Date"
            errors={errors}
            control={control}
            required
            maxLength={100}
            isLoading={false}
            disabled
          />
          <InputForm
            name="ocspConfigUpdateAt"
            label="Update At"
            errors={errors}
            control={control}
            required
            maxLength={100}
            isLoading={false}
            disabled
          />
        </>
      )}
      <Stack
        spacing={2}
        direction="row"
        alignItems="center"
        justifyContent="flex-end"
      >
        <Button
          variant="contained"
          color="gray"
          onClick={() => navigate("/ocsp-configuration")}
        >
          Cancel
        </Button>

        { editFgroup && ocspConfig.update &&
          <Button variant="contained" type="submit">
            Update
          </Button>
        }

        { !editFgroup && ocspConfig.create &&
          <Button variant="contained" type="submit">
            Submit
          </Button>
        }

      </Stack>
    </Box>
    </DetailDataSkeleton>
  );
};

export default OcspConfigurationField;
