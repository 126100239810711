import { ISidebar } from '../interfaces/ISidebar';

// Icon Two Tone
import DashboardTwoToneIcon from '@mui/icons-material/DashboardTwoTone'; // dashboard
// import DevicesTwoToneIcon from '@mui/icons-material/DevicesTwoTone'; // device
import FormatAlignRightIcon from '@mui/icons-material/FormatAlignRight';
import FolderIcon from '@mui/icons-material/Folder';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
// import CardMembershipTwoToneIcon from '@mui/icons-material/CardMembershipTwoTone'; // usercertificate
// import BadgeTwoToneIcon from '@mui/icons-material/BadgeTwoTone'; // officer sertificate
// import SystemUpdateAltTwoToneIcon from '@mui/icons-material/SystemUpdateAltTwoTone'; // system
// import InsertChartTwoToneIcon from '@mui/icons-material/InsertChartTwoTone'; // statistic
// import WebStoriesTwoToneIcon from '@mui/icons-material/WebStoriesTwoTone'; // logs
// import AdminPanelSettingsTwoToneIcon from '@mui/icons-material/AdminPanelSettingsTwoTone'; // admin

export const arrSidebar: Array<ISidebar> = [
  {
    key: 'dashboard',
    name: 'Dashboard',
    path: '/',
    icon: <DashboardTwoToneIcon />,
  },
  // {
  //   key: 'form-type',
  //   name: 'Form Type',
  //   path: '/form-type',
  //   icon: <FormatAlignRightIcon />,
  // },
  { 
    key: 'ocsp-configuration',
    name: 'OCSP Configuration',
    path: '/ocsp-configuration',
    icon: <FolderIcon />,
  },
  // {
  //   key: 'log-events',
  //   name: 'Log Events',
  //   path: '/log-events',
  //   icon: <BadgeTwoToneIcon />,
  // },
  // {
  //   key: 'admin-mng',
  //   name: 'Admin Mng',
  //   path: '/admin-mng',
  //   icon: <AdminPanelSettingsTwoToneIcon />,
  // },
];
