import {
  Avatar,
  Box,
  Breadcrumbs,
  Button,
  IconButton,
  Link,
  Menu,
  MenuItem,
  Toolbar,
  Tooltip,
  Typography,
  useTheme,
} from '@mui/material';
import { useEffect, useState } from 'react';
import MuiAppBar from '@mui/material/AppBar';
import { IOpenMainProps } from '../../../interfaces/IUtils';

// Icons
import MenuIcon from '@mui/icons-material/Menu';
import { useNavigate, useParams, useLocation } from 'react-router-dom';
import { clearStorage } from '../../../utils/storage';
import { useAppDispatch } from '../../../store';
import { removeToken } from '../../../store/feature/user';

const drawerWidth = 320;
const Navbar = ({ open, setOpen }: IOpenMainProps) => {
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const theme = useTheme();
  const settings = ['Profile', 'Account', 'Dashboard', 'Logout'];
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const [offset, setOffset] = useState(0);
  const [anchorElUser, setAnchorElUser] = useState<null | HTMLElement>(null);
  const { pathname } = useLocation();
  const param = useParams();
  const breadcrumb: any = [{ label: 'Dashboard', href: '/' }];

  // REGION: BREADCRUMB NON DYMANIC
  if (pathname === '/ocsp-configuration') {
    breadcrumb.push({ label: 'OCSP Configuration', href: '/ocsp-configuration' });
  } else if (pathname === '/ocsp-configuration/create') {
    breadcrumb.push({ label: 'OCSP Configuration', href: '/ocsp-configuration' });
    breadcrumb.push({ label: 'Create OCSP Configuration ', href: '/ocsp-configuration/create' });
  } else if (pathname === `/ocsp-configuration/edit/${param.id}`) {
    breadcrumb.push({ label: 'OCSP Configuration', href: '/ocsp-configuration' });
    breadcrumb.push({ label: 'Update OCSP Configuration ', href: `/ocsp-configuration/edit/${param.id}` });
  }

  useEffect(() => {
      const onScroll = () => setOffset(window.pageYOffset);
      window.removeEventListener('scroll', onScroll);
      window.addEventListener('scroll', onScroll, { passive: true });
      return () => window.removeEventListener('scroll', onScroll);
  }, []);

  const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElUser(event.currentTarget);
  };

  const handleCloseUserMenu = () => {
    setAnchorElUser(null);
  };

  const handleButton = (setting: any) => {
    if (setting === 'Logout') {
      dispatch(removeToken());
      navigate('/login', { replace: true });
      clearStorage();
    }
  };

  return (
    <MuiAppBar
      position="fixed"
      sx={{
        display: 'flex',
        background: 'transparent',
        // background: 'yellow',
        boxShadow: 'none',
        // backgroundColor: '#B3c100',
        alignItems: 'space-between',
        zIndex: theme.zIndex.drawer + 1,
        transition: theme.transitions.create(['width', 'margin'], {
          easing: theme.transitions.easing.sharp,
          duration: theme.transitions.duration.leavingScreen,
        }),
        ...(open && {
          marginLeft: drawerWidth,
          width: `calc(100% - ${drawerWidth}px)`,
          transition: theme.transitions.create(['width', 'margin'], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.enteringScreen,
          }),
        }),
      }}
    >
      <Toolbar sx={{ borderRadius: 'none', paddingLeft: 0 }} variant="dense">
        <IconButton
          color="inherit"
          aria-label="open drawer"
          onClick={handleDrawerOpen}
          edge="start"
          sx={{
            marginRight: 3.7,
            ...(open && { display: 'none' }),
          }}
        >
          <MenuIcon />
        </IconButton>
        <Box
          sx={{
            flex: 1,
            flexDirection: 'row',
            justifyContent: 'flex-end',
            display: 'flex',
          }}
        >
        
        {
          offset <= 30 &&
          <Breadcrumbs
          aria-label="breadcrumb"
          sx={{
            backgroundColor:'transparent',
            flex:1,
            marginTop:'12px',
            cursor: 'pointer'
            }}
          >
            {breadcrumb.map((item: any, index: Number) => {
              return index != breadcrumb.length-1 ? (
                <Link
                  key={item.label}
                  onClick={() => navigate(item.href)}
                  underline="hover">
                  {item.label}
                </Link>
              ) : (
                <Typography key={item.label} color="textPrimary">
                  {item.label}
                </Typography>
              )
            })}
          </Breadcrumbs>
        }

          <Tooltip title="Open settings">
            <IconButton onClick={handleOpenUserMenu} sx={{ p: 0 }}>
              <Avatar
                alt="Remy Sharp"
                src="https://images.pexels.com/photos/220453/pexels-photo-220453.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
              />
            </IconButton>
          </Tooltip>

          <Menu
            sx={{ mt: '45px' }}
            id="menu-appbar"
            anchorEl={anchorElUser}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={Boolean(anchorElUser)}
            onClose={handleCloseUserMenu}
          >
            {settings.map((setting) => (
              <MenuItem key={setting} onClick={handleCloseUserMenu}>
                {/* <Typography textAlign="center">{setting}</Typography> */}
                <Button onClick={() => handleButton(setting)} sx={{width: '100%', justifyContent: "flex-start", textTransform: "none" , paddingX: '12px'}}>{setting}</Button>
              </MenuItem>
            ))}
          </Menu>

        </Box>
        {/* <Typography variant="h6" noWrap component="div">
            Mini variant drawer */}
        {/* </Typography> */}
        {/* <Navbar /> */}
      </Toolbar>
    </MuiAppBar>
  );
};

export default Navbar;
