import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import userReducer from "./feature/user";
import formGroup from "./feature/formGroup";
import ocspConfigure from "./feature/ocspConfigure";
// import endUser from "./feature/endUser";

export const store = configureStore({
  reducer: {
    user: userReducer,
    fGroup: formGroup,
    ocspConfig: ocspConfigure
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export type RootState = ReturnType<typeof store.getState>;

export type AppDispatch = typeof store.dispatch;

export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
