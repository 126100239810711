import OcspConfiguration from "../pages/ocspConfiguration/OcspConfiguration";
import OcspConfigurationField from "../pages/ocspConfiguration/OcspConfigurationField";
import IRoutes from "../interfaces/IRoutes";
import NotFound from "../pages/notFound/NotFound";
import EmptyPage from "../pages/empty/EmptyPage";

const OcspConfigurationRoute: Array<IRoutes> = [
  {
    element: <OcspConfiguration />,
    path: "/ocsp-configuration",
  },
  {
    element: <OcspConfigurationField />,
    path: "/ocsp-configuration/create",
  },
  {
    element: <OcspConfigurationField />,
    path: "/ocsp-configuration/edit/:id",
  }
];

const OcspConfigurationRouteErr: Array<IRoutes> = [
  {
    element: <NotFound />,
    path: "/ocsp-configuration",
  },
  {
    element: <NotFound />,
    path: "/ocsp-configuration/create",
  },
  {
    element: <NotFound />,
    path: "/ocsp-configuration/edit/:id",
  }
];

const OcspConfigurationRouteEmpty: Array<IRoutes> = [
  {
    element: <EmptyPage />,
    path: "/ocsp-configuration",
  },
  {
    element: <EmptyPage />,
    path: "/ocsp-configuration/create",
  },
  {
    element: <EmptyPage />,
    path: "/ocsp-configuration/edit/:id",
  }
];

export { OcspConfigurationRoute, OcspConfigurationRouteErr, OcspConfigurationRouteEmpty };
