import { Box, Button, Chip, Stack, Typography } from "@mui/material";
import { DataGrid, GridColDef, GridCellParams } from "@mui/x-data-grid";
import { LoadingButton } from "@mui/lab";
import { useNavigate } from "react-router-dom";
import { RootState, useAppDispatch, useAppSelector } from "../../../store";
import { deleteOcspConfiguration } from "../../../store/feature/ocspConfigure";
import PaginationControl from "../pagination/Pagination";
import Swal from "sweetalert2";
import { useEffect } from "react";

const OcspConfigurationTable = ({
  data,
  params,
  setParams,
  defaultValue,
  setDefaultValue,
}: any) => {

  // REGION: INIT VARIABLES
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const { totalConfig } = useAppSelector((store: RootState) => store.ocspConfig);
  const { ocspConfig } = useAppSelector((state: RootState)=> state.user)


  // REGION: RENDER BUTTON CELL
  const customCellRender = (params: GridCellParams) => {

    // REGION: NAVIGATE TO EDIT
    const onEdit = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      navigate(`/ocsp-configuration/edit/${params.row.uid}`);
    };

    // REGION: DISPATCH DELETE DATA
    const onDelete = (e: any) => {
      e.stopPropagation(); // don't select this row after clicking
      Swal.fire({
        title: "Delete Confirmation",
        text: "Are you sure delete this configuration? You won't be able to revert this!",
        icon: "question",
        showCancelButton: true,
        confirmButtonColor: "#d33",
        cancelButtonColor: "#808080",
        confirmButtonText: "Yes",
        reverseButtons: true,
        didOpen: () => Swal.getConfirmButton()?.focus()
      }).then((result) => {
        if (result.isConfirmed) {
          const data = {
            uc_ocsp_config_status: "Deleted",
            uid: params.row.uid
          }
          dispatch(deleteOcspConfiguration(data));
        }
      });
    };

    // REGION: RETURN TSX
    return (
      <>
      { ocspConfig.update || ocspConfig.delete
        ? <Stack spacing={2} direction="row">
            { ocspConfig.update &&
              <Button variant="contained" onClick={onEdit}>
                Update
              </Button>
            }
            { ocspConfig.delete &&
              <Button variant="contained" color="error" onClick={onDelete}>
                Delete
              </Button>
            }
          </Stack>
        : <Typography variant="body2"> No Available Action </Typography>
      }
      </>
    );
  };


  // REGION: INIT COLUMNS
  const columns: GridColDef[] = [
    {
      field: "configId",
      headerName: "Config ID",
      flex: 1,
      minWidth: 250,
      headerClassName: "centered",
    },
    {
      field: "ocspCertificateSubjectName",
      headerName: "OCSP Certificate Subject Name",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "issuerSubjectName",
      headerName: "Issuer Subject Name",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "createdAt",
      headerName: "Created At",
      flex: 1,
      minWidth: 250,
    },
    {
      field: "status",
      headerName: "Status",
      flex: 1,
      minWidth: 250,
      headerAlign: 'center',
      align: 'center',
      renderCell: (params: any) => {
        return (
          <>
            {params.row.status !== null ? (
              params.row.status.toLowerCase() === "active" ? (
                <Chip label="ACTIVE" color="success" variant="outlined" />
              ) : (
                <Chip label="INACTIVE" color="warning" variant="outlined" />
              )
            ) : (
              ""
            )}
          </>
        );
      },
    },
    {
      field: "action",
      headerName: "Action",
      flex: 1,
      minWidth: 320,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      renderCell: customCellRender,
    },
  ];


  // REGION: INIT ROWS
  const rows = data.map((row: any) => ({
    uid: row.uc_ocsp_config_uid,
    id: row.uc_ocsp_config_id,
    configId: row.uc_ocsp_cert_id,
    issuerSubjectName: row.uc_crt_issuer_subject_name,
    ocspCertificateSubjectName: row.uc_ocsp_subject_name,
    createdAt: row.uc_ocsp_config_created_at,
    status: row.uc_ocsp_config_status,
  }));


  // REGION: RETURN TSX
  return (
    <>
      <Box sx={{ height: "650px" }}>
        <DataGrid
          rows={rows}
          columns={columns}
          disableColumnMenu
          hideFooter
          disableColumnSelector
          disableDensitySelector
          disableSelectionOnClick
          // autoHeight
        />
      </Box>
      <PaginationControl params={params} setParams={setParams} total={totalConfig} />
    </>
  );
};

export default OcspConfigurationTable;
