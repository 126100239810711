import { createBrowserRouter, RouterProvider } from 'react-router-dom';
import routes from './routes';
import { RootState, useAppSelector } from './store';
import { OcspConfigurationRoute, OcspConfigurationRouteEmpty, OcspConfigurationRouteErr } from './routes/ocspConfiguration';

function App() {

  const { ocspConfig } = useAppSelector((state: RootState)=> state.user)
  
  const filteredRoutes = routes[0].children?.filter((item:any) =>
    !OcspConfigurationRoute.includes(item) && !OcspConfigurationRouteErr.includes(item) && !OcspConfigurationRouteEmpty.includes(item)
  )

  console.log("ocspConfig", ocspConfig)

  if(ocspConfig.uid != "") {
    if(ocspConfig.uid == undefined) {
      filteredRoutes?.push(...OcspConfigurationRouteErr)
    } else {
      filteredRoutes?.push(...OcspConfigurationRoute)
    }
  } else {
    filteredRoutes?.push(...OcspConfigurationRouteEmpty)
  }

  routes[0].children = filteredRoutes

  const router = createBrowserRouter(routes);
  return <RouterProvider fallbackElement="..loading" router={router} />;
}

export default App;
