import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { instance, instanceEndUser } from "../../service/instance";
import { store } from "../index";
import Swal from "sweetalert2";
import { useTheme } from "@mui/material/styles";

interface IFormGroup {
  isLoadingOcspConfig: boolean;
  isLoadingEvent: boolean;
  dataOcspConfig: any;
  editDataOcspConfig: any;
  optSubjectName: any;
  optIssuerSubjectName: any;
  totalConfig: any;
  msgSccsFGr: string;
  eventFlag: boolean;
}

const initialState: IFormGroup = {
  isLoadingOcspConfig: false,
  isLoadingEvent: false,
  dataOcspConfig: [],
  editDataOcspConfig: {},
  optSubjectName: [],
  optIssuerSubjectName: [],
  totalConfig: 0,
  msgSccsFGr: "",
  eventFlag: false,
};

export const getAllOcspList = createAsyncThunk<any, any, { rejectValue: any }>(
  "ocsp-configure/list",
  async (data, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      const uid = state?.user.ocspConfig.pageUID;
      console.log("plot twist ===> ", state?.user.ocspConfig.pageUID);
      const resp: any = await instance.get(`/ocsp/ocsp-configure`, {
        params: data,
        headers: {
          'page-access-uid': uid,
        },
      });
      const responseData = resp.data.data.data;
      // console.log("responseData: ", responseData)
      let totalRecord = resp.data.data.total_records;
      return { responseData, totalRecord };
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const getOcspById = createAsyncThunk<any, any, { rejectValue: any }>(
  "ocsp-configure/item",
  async (id, { rejectWithValue, getState }) => {
    try {
      const state: any = getState();
      console.log(
        "state?.user.ocspConfig.pageUID ===> ",
        state?.user.ocspConfig.pageUID
      );
      const uid = state?.user.ocspConfig.pageUID;
      const response: any = await instance.get(`/ocsp/ocsp-configure/${id}`, {
        headers: {
          'page-access-uid': uid,
        },
      });
      // console.log("getOcspById: ", response)
      const responseData = response.data.data;
      return responseData;
    } catch (err) {
      throw rejectWithValue(err);
    }
  }
);

export const getAllCertificates = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("certificates/list", async (_, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.ocspConfig.pageUID;
    const response: any = await instanceEndUser.get(`/certificates`, {
      headers: {
        'page-access-uid': uid,
      },
    });
    //console.log("getAllCertificates: ", response.data.data.data)
    const responseData = response.data.data.data;
    const filteredData = responseData.filter(
      (fi: { certificate_status: string }) => fi.certificate_status == "active"
    );
    const optSubjectName = filteredData.map((item: any) => ({
      label: item.certificate_subject_name,
      value: item.certificate_serial_number,
    }));
    const optIssuerSubjectName = filteredData.map((item: any) => ({
      label: item.certificate_issuer_subject_name,
      value: item.certificate_serial_number,
    }));
    const payloadData = {
      optSubjectName: optSubjectName,
      optIssuerSubjectName: optIssuerSubjectName,
    };
    //console.log("payloadData: ", payloadData)
    return payloadData;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const getCertificateDropdown = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("certificates/list-dropdown", async (_, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.ocspConfig.pageUID;
    const response: any = await instanceEndUser.get(`/dropdown/certificates`, {
      headers: {
        'page-access-uid': uid,
      },
    });
    //console.log("getCertificateDropdown: ", response)
    const responseData = response.data.data;
    const options = responseData.map((item: any) => ({
      label: item.certificate_subject_name,
      value: item.certificate_serial_number,
    }));
    const payloadData = {
      optSubjectName: options,
      optIssuerSubjectName: options,
    };
    //console.log("payloadData: ", payloadData)
    return payloadData;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const createOcspConfiguration = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("ocsp-configure/create", async (data, { rejectWithValue, getState }) => {
  console.log("CREATE CONFIG DATA: ", data);
  try {
    const state: any = getState();
    const uid = state?.user.ocspConfig.pageUID;
    const resp: any = await instance.post(`/ocsp/ocsp-configure`, data, {
      headers: {
        'page-access-uid': uid,
      },
    });
    console.log("CREATE CONFIG: ", resp);
    return resp.data;
  } catch (err) {
    console.log("CREATE CONFIG ERR: ", err);
    throw rejectWithValue(err);
  }
});

export const updateOcspConfiguration = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("ocsp-configure/update", async (data, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.ocspConfig.pageUID;
    const resp = await instance.put(`/ocsp/ocsp-configure/${data.uid}`, data, {
      headers: {
        'page-access-uid': uid,
      },
    });
    //console.log("UPDATE CONFIG: ", resp)
    return resp.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const deleteOcspConfiguration = createAsyncThunk<
  any,
  any,
  { rejectValue: any }
>("ocsp-configure/delete", async (data, { rejectWithValue, getState }) => {
  try {
    const state: any = getState();
    const uid = state?.user.ocspConfig.pageUID;
    const resp = await instance.delete(`/ocsp/ocsp-configure/${data.uid}`, {
      headers: {
        'page-access-uid': uid,
      },
    });
    return resp.data;
  } catch (err) {
    throw rejectWithValue(err);
  }
});

export const ocspConfigure = createSlice({
  name: "ocspConfigure",
  initialState,
  reducers: {
    setEventFlag: (state) => {
      state.eventFlag = true;
    },
    removeEventFlag: (state) => {
      state.eventFlag = false;
    },
  },
  extraReducers(builder) {
    builder

      // REGION: GET OCSP ALL
      .addCase(getAllOcspList.pending, (state) => {
        state.isLoadingOcspConfig = true;
      })
      .addCase(getAllOcspList.fulfilled, (state, action) => {
        state.isLoadingOcspConfig = false;
        const { responseData, totalRecord } = action.payload;
        state.dataOcspConfig = responseData;
        state.totalConfig = totalRecord;
      })
      .addCase(getAllOcspList.rejected, (state) => {
        state.isLoadingOcspConfig = false;
        state.dataOcspConfig = [];
        state.totalConfig = 0;
      })

      // REGION: GET OCSP BY ID
      .addCase(getOcspById.pending, (state) => {
        state.isLoadingOcspConfig = true;
      })
      .addCase(getOcspById.fulfilled, (state, action) => {
        state.isLoadingOcspConfig = false;
        state.editDataOcspConfig = action.payload;
      })
      .addCase(getOcspById.rejected, (state) => {
        state.isLoadingOcspConfig = false;
        state.editDataOcspConfig = {};
      })

      // REGION: GET CERTIFCATES
      // CURRENTLY NOT USED
      .addCase(getAllCertificates.fulfilled, (state, action) => {})

      // REGION: GET CERTIFCATES DROPDOWN
      .addCase(getCertificateDropdown.fulfilled, (state, action) => {
        state.optSubjectName = action.payload.optSubjectName;
        state.optIssuerSubjectName = action.payload.optIssuerSubjectName;
      })

      // REGION: CREATE OCSP
      .addCase(createOcspConfiguration.pending, (state) => {
        state.isLoadingEvent = true;
        state.eventFlag = false;
      })
      .addCase(createOcspConfiguration.fulfilled, (state) => {
        state.isLoadingEvent = false;
        Swal.fire({
          icon: "success",
          title: "Configuration Created!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "##2e4054",
          text: "Your configuration has been created",
          didOpen: () => Swal.getConfirmButton()?.focus(),
        }).then(() => {
          store.dispatch(setEventFlag());
        });
      })
      .addCase(createOcspConfiguration.rejected, (state) => {
        state.isLoadingEvent = false;
        Swal.fire({
          icon: "error",
          title: "Create Configuration Error!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#2e4054",
          text: "Can not create your configuration, Please try again",
          didOpen: () => Swal.getConfirmButton()?.focus(),
        }).then(() => {
          store.dispatch(removeEventFlag());
        });
      })

      // REGION: UPDATE OCSP
      .addCase(updateOcspConfiguration.pending, (state) => {
        state.isLoadingEvent = true;
        state.eventFlag = false;
      })
      .addCase(updateOcspConfiguration.fulfilled, (state, dispatch) => {
        state.isLoadingEvent = false;
        Swal.fire({
          icon: "success",
          title: "Configuration Updated!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#2e4054",
          text: "Your configuration has been updated",
          didOpen: () => Swal.getConfirmButton()?.focus(),
        }).then(() => {
          store.dispatch(setEventFlag());
        });
      })
      .addCase(updateOcspConfiguration.rejected, (state) => {
        state.isLoadingEvent = false;
        Swal.fire({
          icon: "error",
          title: "Update Configuration Error!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#2e4054",
          text: "Can not update your configuration, Please try again",
          didOpen: () => Swal.getConfirmButton()?.focus(),
        }).then(() => {
          store.dispatch(removeEventFlag());
        });
      })

      // REGION: DELETE OCSP
      .addCase(deleteOcspConfiguration.pending, (state) => {
        state.isLoadingEvent = true;
        state.eventFlag = false;
      })
      .addCase(deleteOcspConfiguration.fulfilled, (state) => {
        state.isLoadingEvent = false;
        Swal.fire({
          icon: "success",
          title: "Configuration Deleted!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#2e4054",
          text: "Your configuration has been deleted",
          didOpen: () => Swal.getConfirmButton()?.focus(),
        }).then(() => {
          store.dispatch(setEventFlag());
        });
      })
      .addCase(deleteOcspConfiguration.rejected, (state) => {
        state.isLoadingEvent = false;
        Swal.fire({
          icon: "error",
          title: "Delete Configuration Error!",
          showCancelButton: false,
          confirmButtonText: "OK",
          confirmButtonColor: "#2e4054",
          text: "Can not delete your configuration, Please try again",
          didOpen: () => Swal.getConfirmButton()?.focus(),
        }).then(() => {
          store.dispatch(removeEventFlag());
        });
      });
  },
});

export const { setEventFlag, removeEventFlag } = ocspConfigure.actions;
export default ocspConfigure.reducer;
