import { Box, Button, Typography } from "@mui/material";
import { useEffect, useState } from "react";
import { useAppDispatch, useAppSelector, RootState } from "../../store";
import { useNavigate } from "react-router-dom";
import {
  getAllOcspList,
  removeEventFlag,
} from "../../store/feature/ocspConfigure";
import OcspConfigurationSearch from "../../components/molecules/ocspConfiguration/OcspConfigurationSearch";
import OcspConfigurationTable from "../../components/molecules/ocspConfiguration/OcspConfigurationTable";
import ListDataSkeleton from "../../components/template/skeleton/ListDataSkeleton";
import AddIcon from "@mui/icons-material/Add";

const OcspConfiguration = () => {
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const [params, setParams] = useState<object>({});
  const [defaultValue, setDefaultValue] = useState({});
  const { dataOcspConfig, isLoadingOcspConfig, isLoadingEvent, eventFlag } =
    useAppSelector((state: RootState) => state.ocspConfig);
  const { ocspConfig } = useAppSelector((state: RootState) => state.user);

  // REGION: DISPATCH OCSP ALL
  useEffect(() => {
    dispatch(getAllOcspList(params));
  }, [params]);

  // REGION: DISPATCH SUCCESS EVENT
  useEffect(() => {
    if (eventFlag) {
      dispatch(getAllOcspList(params));
      dispatch(removeEventFlag());
    }
  }, [eventFlag]);

  // REGION: RETURN TSX
  return (
    <ListDataSkeleton
      isLoading={isLoadingOcspConfig}
      isLoadingEvent={isLoadingEvent}
      titleWidth={"300px"}
    >
      <Box>
        <Typography
          variant="h3"
          fontFamily="Open Sans"
          sx={{ marginY: "20px" }}
        >
          OCSP Configuration
        </Typography>
        {ocspConfig.create && (
          <Box sx={{ display: "flex", flexDirection: "row-reverse" }}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => navigate("/ocsp-configuration/create")}
              sx={{ marginBottom: "18px" }}
            >
              <AddIcon /> Create
            </Button>
          </Box>
        )}
        <OcspConfigurationSearch params={params} setParams={setParams} />
        <OcspConfigurationTable
          data={dataOcspConfig}
          params={params}
          setParams={setParams}
          defaultValue={defaultValue}
          setDefaultValue={setDefaultValue}
        />
      </Box>
    </ListDataSkeleton>
  );
};

export default OcspConfiguration;
